import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import {
    FaBehance,
    FaDribbble,
    FaFacebookF,
    FaGithub,
    FaLinkedinIn,
    FaTumblr,
    FaTwitter,
} from "react-icons/fa";
import { Fade } from "react-reveal";
import ContainerDiv from "../components/layouts/ContainerDiv";
import PageHeader from "../components/layouts/PageHeader";
import PageLayout from "../components/layouts/PageLayout";
const Developer = ({ person }) => {
    const {
        src,
        name,
        position,
        linkedIn,
        github,
        facebook,
        tumblr,
        twitter,
        behance,
        dribbble,
    } = person;
    return (
        <div
            className="flex flex-col group cursor-pointer p-6 justify-start items-start h-full
        "
        >
            <img
                src={src}
                alt=""
                className="aspect-square rounded-full bg-gray-200 w-full mb-4 group-hover:shadow-2xl duration-200 lg:grayscale group-hover:grayscale-0"
            />
            <div className="w-full flex flex-col items-center justify-start duration-200 relative">
                <h5 className="mb-2 text-lg text-secondaryText text-center font-bold group-hover:opacity-0 duration-200 opacity-100">
                    {name}
                </h5>
                <p className="text-sm text-primaryText group-hover:opacity-0 duration-200 opacity-200 text-center">
                    {position}
                </p>

                <div className="w-full flex items-center justify-center group-hover:opacity-100 duration-200 absolute top-0 left-0 h-full opacity-0">
                    {linkedIn?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={linkedIn}
                            className="rounded-full flex items-center justify-center bg-[#0b5fbb] w-9 h-9 mx-2"
                        >
                            <FaLinkedinIn className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {github?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={github}
                            className="rounded-full flex items-center justify-center bg-[#000] w-9 h-9 mx-2"
                        >
                            <FaGithub className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {facebook?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={facebook}
                            className="rounded-full flex items-center justify-center bg-[#1275B1] w-9 h-9 mx-2"
                        >
                            <FaFacebookF className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {twitter?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={twitter}
                            className="rounded-full flex items-center justify-center bg-[#1c99e6] w-9 h-9 mx-2"
                        >
                            <FaTwitter className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {tumblr?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={tumblr}
                            className="rounded-full flex items-center justify-center bg-[#1d3764] w-9 h-9 mx-2"
                        >
                            <FaTumblr className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {behance?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={behance}
                            className="rounded-full flex items-center justify-center bg-[#0253f2] w-9 h-9 mx-2"
                        >
                            <FaBehance className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                    {dribbble?.length > 0 ? (
                        <Link
                            target={`_blank`}
                            to={dribbble}
                            className="rounded-full flex items-center justify-center bg-[#0253f2] w-9 h-9 mx-2"
                        >
                            <FaDribbble className="text-xl text-white" />
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

const Leaders = ({ data }) => {
    return (
        <PageLayout>
            <PageHeader title={"Leaders"} />
            <ContainerDiv className={`pt-[90px] flex !flex-col`}>
                <div className="absolute left-0 w-full h-full">
                    <Img
                        fluid={data.diamond.childImageSharp.fluid}
                        className="w-[444pt] -ml-[235pt] mt-[188pt]"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 w-full py-12">
                    <Fade bottom cascade delay={200}>
                        <div className="col-span-6 flex justify-center flex-col">
                            <h1 className="mb-6 font-bold text-secondaryText text-3xl text-left">
                                Global OR Leaders
                            </h1>
                            <p className="mb-4 text-base">
                                Michelle Park and Neil Bathan have created a
                                platform allowing nurses to access and retrieve
                                real-time information on surgeon procedure
                                cards. They have 30-plus years of experience
                                working as scrub nurses and leaders in the OR.{" "}
                            </p>
                            <p className="text-base">
                                Their inside knowledge of the industry and
                                identifying the pain points and shortcomings
                                have enabled them to create an intuitive,
                                user-friendly product that appeals to both old
                                and new generations of health care professionals
                                working in the operating room.
                            </p>
                        </div>
                    </Fade>
                    <div className="col-span-6">
                        <Fade bottom cascade delay={400}>
                            <Img fluid={data.leaders.childImageSharp.fluid} />
                        </Fade>
                    </div>
                </div>
                <Fade bottom cascade delay={200}>
                    <div className="col-span-12 flex flex-col justify-center items-center mt-4">
                        <h2 className="text-2xl mb-6 text-secondaryText font-bold text-center">
                            Meet the Team
                        </h2>
                        <p className=" text-primaryText text-center px-11; md:px-0 break-auto">
                            We work together to put the healthcare professional
                            first.
                        </p>
                    </div>
                </Fade>
                <div className="flex flex-col md:flex-row flex-wrap w-full py-12 justify-center items-center">
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={300}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/charliemagneclaros.svg?b=%238fceff&top%5B%5D=shortCurly&topChance=100&hairColor%5B%5D=black&accessories%5B%5D=prescription02&accessoriesChance=100&accessoriesColor%5B%5D=black&facialHairChance=0&facialHairColor%5B%5D=black&clothes%5B%5D=shirtCrewNeck&clothesColor%5B%5D=blue03&eyes%5B%5D=happy&eyebrow%5B%5D=defaultNatural&mouth%5B%5D=smile&skin%5B%5D=light`,
                                    name: "Charliemagne Claros",
                                    position:
                                        "Project Manager | Lead Mobile Application Developer",
                                    linkedIn:
                                        "https://www.linkedin.com/in/charliemagne-claros-425256136/",
                                    github: "https://github.com/ccharliemagne",
                                    facebook:
                                        "https://www.facebook.com/profile.php?id=100014481062413",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={400}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/defensil.svg?top[]=shortCurly&topChance=100&accessories[]=sunglasses&accessoriesChance=100&eyes[]=default&mouth[]=smile`,
                                    name: "Ivan Krister B. Garcia",
                                    position: "Senior Back-end Developer",
                                    linkedIn:
                                        "https://www.linkedin.com/in/ivan-krister-0126751b0/",
                                    facebook:
                                        "https://www.facebook.com/kristernavi/",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={500}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/joe.svg?b=%23fff0d6&top[]=dreads01&topChance=84&hairColor[]=black&accessories[]=prescription01&accessoriesChance=100&accessoriesColor[]=blue03&facialHair[]=light&facialHairChance=100&facialHairColor[]=black&clothes[]=sweater&clothesColor[]=blue03&eyes[]=default&eyebrow[]=default&mouth[]=smile&skin[]=brown`,
                                    name: "Joel V. Baluma",
                                    position: "Senior Front-end Developer",
                                    tumblr: "https://joelbaluma.tumblr.com/",
                                    facebook:
                                        "https://www.facebook.com/joelvarquezbaluma",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={600}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/warren.svg?b=%23525252&top%5B%5D=shortHair&topChance=0&facialHair%5B%5D=light&facialHairChance=100&facialHairColor%5B%5D=black&clothes%5B%5D=hoodie&clothesColor%5B%5D=black&eyes%5B%5D=happy&eyebrow%5B%5D=default&mouth%5B%5D=smile&skin%5B%5D=light`,
                                    name: "John Warren T. Bagcat",
                                    position: "UI/UX Generalist",
                                    linkedIn:
                                        "https://www.linkedin.com/in/john-warren-bagcat/",
                                    behance:
                                        "https://www.behance.net/johnwarrenbagcat",
                                    dribbble: "https://dribbble.com/johnwarren",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={700}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/your-custom-seed.svg?b=%2313b6ec&top[]=longHair&top[]=turban&topChance=100&hairColor[]=pastel&accessories[]=wayfarers&accessoriesChance=100&facialHairChance=0&clothes[]=sweater&clothesColor[]=pastelRed&eyes[]=happy&mouth[]=smile&mouth[]=twinkle&mouth[]=eating`,
                                    name: "Carolyn B. Lopez ",
                                    position: "Software Test Engineer",
                                    linkedIn:
                                        "https://www.linkedin.com/in/carolyn-lopez-a63204244/",
                                    facebook:
                                        "https://www.facebook.com/karolyn.lpz",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={800}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/croodles/kcvfdxg.svg`,
                                    name: "Hazel A. Dehing",
                                    position: "Software Test Engineer ",
                                    twitter:
                                        "https://twitter.com/_LifeinTropics",
                                    facebook:
                                        "https://www.facebook.com/LifeNTropics",
                                    tumblr: "https://lifeintropics.tumblr.com",
                                }}
                            />
                        </Fade>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3">
                        <Fade bottom delay={800}>
                            <Developer
                                person={{
                                    src: `https://avatars.dicebear.com/api/avataaars/.svg?b=%231adcea&top[]=shortHair&topChance=100&hairColor[]=black&accessoriesChance=0&facialHair[]=light&facialHairChance=100&facialHairColor[]=black&clothes[]=sweater&clothesColor[]=gray02&eyes[]=side&eyebrow[]=up&mouth[]=default&skin[]=light`,
                                    name: "Christian Joseph C. Sarmiento",
                                    position:
                                        "Junior Mobile Application Developer",
                                    linkedIn:
                                        "https://www.linkedin.com/in/christian-sarmiento-423336121/",
                                    github: "https://github.com/Jabeys",
                                    facebook: "https://www.facebook.com/Jabeys",
                                }}
                            />
                        </Fade>
                    </div>
                </div>
            </ContainerDiv>
        </PageLayout>
    );
};

export default Leaders;

export const query = graphql`
    query {
        leaders: file(relativePath: { eq: "leaders-v2.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        diamond: file(relativePath: { eq: "diamond.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
