import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FiArrowRight } from "react-icons/fi";
import Button from "../Button";

const PageHeader = ({ className, title = "", children }) => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "corh.png" }) {
                childImageSharp {
                    fixed(width: 98) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <div
            className={`h-[90px] w-full flex items-center justify-start fixed z-40 p-6 bg-white border-b  ${
                className ? className : ""
            }`}
        >
            <Link to="/" className="z-20">
                <Img
                    fixed={data.logo.childImageSharp.fixed}
                    alt=""
                    className={`duration-300 `}
                />
            </Link>
            <span className="ml-6 text-lg text-primaryText z-20">{title}</span>
            {children}
            <Link
                to="https://hub.corh.app/login"
                className={`hidden md:block ml-auto z-20`}
            >
                <Button type="tertiary">
                    Login
                    <FiArrowRight className="ml-1 text-lg" />
                </Button>
            </Link>
        </div>
    );
};

export default PageHeader;
