import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../../images/corh-logo.svg";
import Footer from "./Footer";

const PageLayout = ({ children, showFooter = true }) => {
    return (
        <main className="min-h-full w-full">
            <Helmet>
                <meta charSet="utf-8" />
                <title>CORH</title>
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon}
                    sizes="16x16"
                />
            </Helmet>
            {children}
            {showFooter ?? <Footer />}
        </main>
    );
};

export default PageLayout;
